import React from 'react';
import PropTypes from 'prop-types';
import InputBase from '@material-ui/core/InputBase';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { formInputFieldStyle } from './style';

function FormTextInput(props) {
  const {
    valid,
    onChangeInput,
    validationMessage,
    value,
    field,
    type,
    style,
    isDisabled,
    length,
    multiline,
    rows,
    touched,
    validFormat = true,
    classes,
    boldMode = false,
    placeholder,
    errorMode = { name: '', message: '' },
    startAdornment,
    endAdornment,
    inputComponent,
    inputMode,
    onKeyDown,
  } = props;
  const inputProps = { maxLength: length, inputMode };

  return (
    <div>
      <InputBase
        id={field}
        name={field}
        fullWidth
        disabled={!!isDisabled}
        classes={{
          root: classes.root,
          input: boldMode ? classes.textBoldInput : classes.input,
          adornedStart: classes.adornedStart,
          inputAdornedStart: classes.inputAdornedStart,
        }}
        value={value}
        onChange={(e) => onChangeInput(e.target.value, field)}
        onKeyDown={onKeyDown}
        style={style}
        inputProps={inputProps}
        autoComplete="off"
        variant="outlined"
        type={type}
        multiline={multiline}
        rows={rows}
        placeholder={placeholder}
        startAdornment={startAdornment}
        endAdornment={endAdornment}
        inputComponent={inputComponent}
      />
      <span
        style={{
          color: '#d32f2f',
          display: (touched && !valid) || (touched && !validFormat) ? 'block' : 'none',
        }}
      >
        {!valid && validationMessage}
        {(errorMode.name === 'string' ||
          errorMode.name === 'email' ||
          errorMode.name === 'phoneNumber' ||
          errorMode.name === 'stringLength') &&
          !validFormat &&
          valid &&
          errorMode.message}
      </span>
    </div>
  );
}

FormTextInput.propTypes = {
  valid: PropTypes.bool,
  onChangeInput: PropTypes.func,
  validationMessage: PropTypes.string,
  value: PropTypes.any,
  field: PropTypes.string,
  type: PropTypes.string,
  style: PropTypes.object,
  isDisabled: PropTypes.bool,
  length: PropTypes.number,
  classes: PropTypes.object,
  multiline: PropTypes.bool,
  rows: PropTypes.any,
  touched: PropTypes.bool,
  validFormat: PropTypes.bool,
  boldMode: PropTypes.bool,
  placeholder: PropTypes.string,
  errorMode: PropTypes.object,
  startAdornment: PropTypes.node,
  endAdornment: PropTypes.node,
  inputComponent: PropTypes.elementType,
  inputMode: PropTypes.string,
  onKeyDown: PropTypes.func,
};
export default compose(withStyles(formInputFieldStyle))(FormTextInput);
