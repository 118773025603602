import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import moment from 'moment-timezone';
import Pagination from '@material-ui/lab/Pagination';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { customization } from 'customization/default';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Deposit from 'assets/userTransactions/Deposit.png';
import Exchange from 'assets/icons/Exchange.png';
import ReceiptIcon from '@material-ui/icons/Receipt';
import { receiptStatusType } from '@data/constants';
import Button from '@material-ui/core/Button';
import * as userActions from 'redux/user/userActions';
import ErrorMessageDialog from 'components/errorMessageDialog';
import FormCheckBox from 'components/formCheckBox';
import ThreeDotsIcon from 'assets/icons/three-dots.png';
import TransactionDetailView from 'containers/transactionDetailView/index';
import { transactionTypeCode, transactionTypeFrom } from 'data/constants';
import { apiRequestStatus } from '@data/constants';
import { transactionListViewStyle } from './style';

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

function TransactionViewList(props) {
  const PAGE_LIMIT = 10;

  const {
    classes,
    data,
    open,
    handleClose,
    total,
    transactionList,
    errorResponse,
    userActions,
    token,
    timezone,
    typeData,
    loading,
  } = props;

  useEffect(() => {
    if (
      open &&
      errorResponse.statusCode &&
      errorResponse.message !== customization.transactionViewListDialog.ignoreErrorMessage
    ) {
      handleErrorRetrieveData(errorResponse.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorResponse]);

  const [errorMessage, setErrorMessage] = useState({ isError: false, message: '' });
  const [openTransactionListDialog, setOpenTransactionListDialog] = useState(false);
  const [activeTransaction, setActiveTransaction] = useState();
  const [page, setPage] = useState(1);

  const handleErrorRetrieveData = (errorMessage) => {
    setErrorMessage({
      isError: true,
      message: errorMessage,
    });
  };

  const handleCloseErrorMessage = () => {
    setErrorMessage({
      isError: false,
      message: '',
    });
  };

  const handleOpenTransactionList = async () => {
    setOpenTransactionListDialog(true);
  };

  const handleCloseTransactionList = async () => {
    setOpenTransactionListDialog(false);
  };

  const parseBalance = (amount) => {
    const transactionViewAmount = amount?.value;
    const transactionViewSymbol = amount?.symbol || '$';

    if (transactionViewAmount) {
      if (transactionViewAmount.charAt(0) === '-') {
        return `-${transactionViewSymbol}${transactionViewAmount.slice(1)}`;
      }
      return `${transactionViewSymbol}${transactionViewAmount}`;
    } else {
      return <div></div>;
    }
  };

  const [includeOtherSpends, setIncludeOtherSpends] = useState(false);

  useEffect(() => {
    if (open && data?.id && token) {
      userActions.getTransactionList(
        data?.id,
        token,
        includeOtherSpends,
        typeData,
        PAGE_LIMIT,
        PAGE_LIMIT * (page - 1),
      );
    }
  }, [open, data, token, page, includeOtherSpends, typeData, userActions]);

  const onChangeInput = (eventValue, inputIdentifier) => {
    setIncludeOtherSpends(eventValue);
  };

  const renderMoreButton = (transaction) => {
    const moreBtn = (
      <Button
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        variant="outlined"
        className={classes.moreButtonStyle}
        onClick={() => {
          handleOpenTransactionList();
          setActiveTransaction(transaction);
        }}
      >
        <img
          src={ThreeDotsIcon}
          alt="threedot_icon"
          loading="lazy"
          className={classes.iconMoreBtnStyle}
        />
      </Button>
    );

    return moreBtn;
  };

  const handleCloseDialog = () => {
    handleClose();
    setIncludeOtherSpends(false);
    setPage(1);
  };

  const tableHeader = [
    {
      title: 'Description/Merchant',
      classes: classes.bigTitleCellStyle,
    },
    {
      title: 'Date',
      classes: classes.commonTitleCellStyle,
    },
    {
      title: 'Amount',
      classes: classes.commonTitleCellStyle,
    },
    {
      title: 'Card Holder',
      classes: classes.commonTitleCellStyle,
    },
    {
      title: 'Account',
      classes: classes.commonTitleCellStyle,
    },
    {
      title: 'Receipt',
      classes: classes.smallTitleCellStyle,
    },
    {
      title: 'Action',
      classes: classes.actionTitleCellStyle,
    },
  ];

  const renderIcon = (typeCode) => {
    let imgSrc, imgAlt;
    switch (typeCode) {
      case transactionTypeCode.DEPOSIT:
        imgSrc = Deposit;
        imgAlt = 'Deposit';
        break;
      default:
        imgSrc = Exchange;
        imgAlt = 'Exchange';
        break;
    }

    return <img src={imgSrc} alt={imgAlt} loading="lazy" />;
  };

  const renderTitle = (data, typeData) => {
    switch (typeData) {
      case transactionTypeFrom.USER:
        return `${data?.name}`;
      case transactionTypeFrom.LOCATION:
        return `${data?.description} ${data?.code}`;
      default:
        return '';
    }
  };

  const receiptStatus = (status) => {
    if (status === receiptStatusType.MATCH) return '#3E8250';
    if (status === receiptStatusType.PARTIALLY_MATCH) return '#FF7842';
    if (status === receiptStatusType.NOT_MATCH) return '#E10454';
    return '#000';
  };

  return (
    <div>
      <ErrorMessageDialog
        bindHandleClose={handleCloseErrorMessage}
        openSuccess={errorMessage.isError}
        errorData={errorMessage.message}
      />
      <TransactionDetailView
        open={openTransactionListDialog}
        handleClose={handleCloseTransactionList}
        data={data}
        typeData={typeData}
        transaction={activeTransaction}
      />
      <Dialog fullWidth maxWidth="xl" open={open} aria-labelledby="form-dialog-title">
        <Toolbar className={classes.toolbar}>
          <Grid container direction="column" className={classes.container}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              className={classes.noWrap}
            >
              <Grid item>
                <Typography variant="h6">
                  <b>
                    {customization.transactionViewListDialog.title} (
                    {renderTitle(data, typeData)})
                  </b>
                </Typography>
              </Grid>
              <Grid item>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleCloseDialog}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            {typeData === transactionTypeFrom.USER && (
              <Grid container className={classes.checkboxWrapper}>
                <FormCheckBox
                  field="includeOtherSpends"
                  label="Include spend for other Users/Locations"
                  onChangeInput={onChangeInput}
                />
              </Grid>
            )}
          </Grid>
        </Toolbar>
        <DialogContent>
          <TableContainer>
            <Table className={classes.table} aria-label="table">
              <TableHead>
                <TableRow className={classes.titleRowStyle}>
                  {tableHeader.map((header, id) => {
                    return (
                      <TableCell className={header.classes} key={id}>
                        <Typography>
                          <b>{header.title}</b>
                        </Typography>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {loading === apiRequestStatus.PENDING && (
                  <TableRow>
                    <TableCell className={classes.loading}>
                      <CircularProgress color="primary" />
                    </TableCell>
                  </TableRow>
                )}

                {total > 0 &&
                  transactionList.map((transaction, index) => {
                    const cardHolder = `${transaction?.transaction_details?.card_holder?.first_name} ${transaction?.transaction_details?.card_holder?.last_name}`;
                    return (
                      <Fragment key={`${transaction.id}${index}`}>
                        <TableRow
                          classes={{
                            root: transaction.is_disputed
                              ? classes.paperInactiveCellStyle
                              : classes.paperActiveCellStyle,
                          }}
                        >
                          <TableCell className={classes.bigCellStyle}>
                            {renderIcon(transaction?.transaction?.transaction_type_code)}
                            <HtmlTooltip
                              title={
                                <React.Fragment>
                                  <Typography color="inherit">
                                    {transaction?.transaction_details?.merchant?.name}
                                  </Typography>
                                </React.Fragment>
                              }
                              placement="top-end"
                            >
                              <b className={classes.typography}>
                                {transaction?.transaction_details?.merchant?.name
                                  ? transaction?.transaction_details?.merchant?.name.substring(
                                      0,
                                      20,
                                    ) + '...'
                                  : ''}
                              </b>
                            </HtmlTooltip>
                          </TableCell>
                          <TableCell className={classes.commonCellStyle}>
                            <b className={classes.typography}>
                              {transaction?.occured_at
                                ? moment(transaction?.occured_at)
                                    .utc()
                                    .tz(timezone)
                                    .format('DD/MM/YYYY - hh:mm a')
                                : 'N/A'}
                            </b>
                          </TableCell>
                          <TableCell className={classes.commonCellStyle}>
                            <b className={classes.typography}>
                              {parseBalance(
                                transaction?.transaction_details?.actual_amount,
                              )}
                            </b>
                          </TableCell>
                          <TableCell className={classes.commonCellStyle}>
                            <HtmlTooltip
                              title={
                                <React.Fragment>
                                  <Typography color="inherit">{cardHolder}</Typography>
                                </React.Fragment>
                              }
                              placement="top-end"
                            >
                              <b className={classes.typography}>
                                {cardHolder !== 'null null'
                                  ? cardHolder.substring(0, 20) +
                                    (cardHolder.length > 20 ? '...' : '')
                                  : ''}
                              </b>
                            </HtmlTooltip>
                          </TableCell>
                          <TableCell className={classes.commonCellStyle}>
                            <HtmlTooltip
                              title={
                                <React.Fragment>
                                  <Typography color="inherit">
                                    {transaction?.accounts?.name}
                                  </Typography>
                                </React.Fragment>
                              }
                              placement="top-end"
                            >
                              <b className={classes.typography}>
                                {transaction?.accounts?.name
                                  ? transaction?.accounts?.name.substring(0, 20) +
                                    (transaction?.accounts?.name.length > 20 ? '...' : '')
                                  : ''}
                              </b>
                            </HtmlTooltip>
                          </TableCell>
                          <TableCell className={classes.smallCellStyle}>
                            <ReceiptIcon
                              fontSize="small"
                              htmlColor={receiptStatus(transaction?.receipt_status)}
                            />
                          </TableCell>
                          <TableCell className={classes.btnActionCellStyle}>
                            {renderMoreButton(transaction)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            colSpan={3}
                            className={classes.emptyTableCellStyle}
                          ></TableCell>
                        </TableRow>
                      </Fragment>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            className={classes.buttonSection}
            spacing={1}
          >
            <Pagination
              count={Math.ceil(total / 10)}
              page={page}
              onChange={(_, value) => setPage(value)}
              shape="rounded"
            />
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

TransactionViewList.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  total: PropTypes.number,
  transactionList: PropTypes.array,
  errorResponse: PropTypes.object,
  token: PropTypes.string,
  typeData: PropTypes.string,
  timezone: PropTypes.string,
  userActions: PropTypes.object,
  loading: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    total: state.user.total,
    transactionList: state.user.transactionList,
    loading: state.user.loading,
    errorResponse: state.user.error,
    token: state.auth.inMemoryToken.token,
    timezone: state.auth.timezone,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    userActions: bindActionCreators(userActions, dispatch),
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(
  withStyles(transactionListViewStyle),
  withConnect,
)(TransactionViewList);
